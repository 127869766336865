<template>
    <div class="scan-page">

      <scanner
          v-if="!modalOpen"
          @barcodeHasBeenScanned="barcodeScanned($event)"
          ref="barcodeScanner"
      ></scanner>

        <div class="scan-content" v-if="device">
            <!-- Empty State -->
            <div class="container" v-if="!deviceParts.length && !devicePartsFaulty.length && !device.no_parts_used">
                <empty-state
                    class="mb-5 p-0"
                    icon="scanner"
                    title="Scan a part to add to the job">
                    <slot>
                        <div class="row">
                            <div class="col-md-5 offset-md-1">
                                <button class="btn btn-danger btn-block mb-3 text-no-wrap m-0" @click="openPartFaultyModal()">{{ faultyPartButtonText }}</button>
                            </div>
                            <div class="col-md-5">
                                <button class="btn btn-light btn-block mb-3" @click="openNoPartsRequiredModal">No parts are required</button>
                            </div>
                        </div>
                    </slot>
                </empty-state>
            </div>
            <!-- Empty State - No parts required -->
            <div class="container" v-if="!deviceParts.length && !devicePartsFaulty.length && device.no_parts_used">
                <empty-state
                    class="mb-5 p-0"
                    icon="scanner"
                    title="No parts were required"
                    subtitle="Scan a part to add to the job if required">
                </empty-state>
            </div>

            <!-- Details -->
            <template v-if="deviceParts.length > 0 || devicePartsFaulty.length > 0">
                <div class="scan-details">
                    <div class="container">
                        <h4 class="d-flex align-items-center my-2">Parts added to job</h4>
                    </div>
                </div>

                <div class="container repair-container scan-content">
                    <!-- Controls -->
                    <div class="card-grid card-grid-scroll auto-width mb-0 pt-0 grid-auto-columns-sm">
                        <button class="btn btn-danger btn-sm d-md-inline-block text-no-wrap m-0" @click="openRemovePartModal()">Remove part</button>
                        <button class="btn btn-danger btn-sm d-md-inline-block text-no-wrap m-0" @click="openPartFaultyModal()">Part faulty</button>
                        <button class="btn btn-info btn-sm d-md-inline-block text-no-wrap m-0" :disabled="devicePartsFaulty.length === 0" @click="openPartNotFaultyModal()">Part not faulty</button>
                    </div>

                    <!-- Part list -->
                    <ul class="list-group mb-3">
                        <li class="list-group-item bg-secondary cursor-default py-2" v-for="part in deviceParts" :key="part.id">
                            <div>{{ part.stock_item_title }}</div>
                        </li>
                    </ul>

                    <!-- Faulty part list -->
                    <template v-if="devicePartsFaulty.length > 0">
                        <div class="alert alert-info" v-if="deviceParts.length === 0">
                            <animated-info></animated-info> If you don't have the required parts to complete this repair, please book a revisit by tapping the progress bar at the top of the screen, selecting Change Status and then updating the device status.
                        </div>
                        <label>Faulty parts</label>
                        <ul class="list-group">
                            <li class="list-group-item bg-secondary cursor-default py-2" v-for="part in devicePartsFaulty" :key="part.id">
                                <div>{{ part.stock_item_title }}</div>
                            </li>
                        </ul>
                    </template>

                </div>
            </template>
        </div>

        <fixed-footer
            :class="{'active': footerActive}"
            :nextLabel="this.nextRepairStep($route.name).title"
            :nextRoute="this.nextRepairStep($route.name).routeName"
            :prevRoute="this.prevRepairStep($route.name).routeName">
        </fixed-footer>

        <!-- Adding Part Modal -->
        <adding-part-modal
            ref="addingPartModal"
        ></adding-part-modal>

        <!-- Scan Part Serial Number Modal -->
        <part-serial-no-scan-modal
            ref="serialNoScanModal"
            v-if="barcode"
            :barcode="barcode"
            :device-id="deviceId"
            :job-id="jobId"
            :serial-type-id="serialTypeId"
            @close="closeOpenModal"
            @serialsScanned="serialsScanned">
        </part-serial-no-scan-modal>

        <!-- Part Fault Status Modal -->
        <part-fault-status-modal
            @close="closeOpenModal">
        </part-fault-status-modal>

        <!-- Remove Part Modal -->
        <remove-part-modal
            @close="closeOpenModal"
            :device-id="deviceId"
            :job-id="jobId"
            @partRemoved="partRemovedToast">
        </remove-part-modal>

        <!-- Part Faulty Modal -->
        <part-faulty-modal
            @close="closeOpenModal"
            :device-id="deviceId"
            :job-id="jobId"
            @partMarkedFaulty="partMarkedFaultyToast">
        </part-faulty-modal>

        <!-- Part Not Faulty Modal -->
        <part-not-faulty-modal
            @close="closeOpenModal"
            :device-id="deviceId"
            :job-id="jobId"
            @partMarkedNotFaulty="partMarkedNotFaulty">
        </part-not-faulty-modal>

        <!-- No Parts Required Modal -->
        <no-parts-required-modal
            @close="closeOpenModal"
            :device-id="deviceId"
            :job-id="jobId">
        </no-parts-required-modal>

    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {

    props:['jobId', 'deviceId'],

    data() {
        return {
            adding: false,
            barcode: null,
            serialTypeId: null,
            modalOpen: false,
            partOrderCategory: null,
            addingPart: false
        }
    },

    mounted() {
        this.loadDeviceForJob({
            jobId: this.jobId,
            deviceId: this.deviceId,
        }).then(() => {
            this.setCurrentStep({
                jobId: this.jobId,
                deviceId: this.deviceId,
                currentStep: this.$route.name,
            });
        });


        this.loadDeviceModelStockItemTypesRequiredSerials({
            jobId: this.jobId,
            deviceId: this.deviceId,
        });

        this.loadPartLists();

        this.modalOpen = false;
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    computed: {
        ...mapGetters([
            "appRegion",
            "device",
            "deviceParts",
            "devicePartsFaulty",
            "isJobManager",
            "job",
            "nextRepairStep",
            "prevRepairStep",
            "stockItem",
            "deviceModelStockItemTypesRequiredSerials"
        ]),

        /** Check if footer should be active */
        footerActive() {
            if (this.job.job_type === "revisit") {
                return true;
            }
            if (this.deviceParts.length > 0 || (this.device && this.device.no_parts_used) || this.isJobManager) {
                return true;
            }

            return false;
        },

        faultyPartButtonText() {
            // if (false) { Switch this with the below if functionality causes issues, to turn it off
            if (this.appRegion === 'UK') {
                return 'Mark part as faulty/broken';
            }
            return 'Mark part as faulty';
        }
    },

    methods: {
        ...mapActions([
            "addPart",
            "displayToast",
            "loadDeviceForJob",
            "loadFaultyParts",
            "loadParts",
            "loadStockItem",
            "loadDeviceModelStockItemTypesRequiredSerials",
            "setCurrentStep"
        ]),

        /** Barcode scanned */
        barcodeScanned(barcode) {
            let canAddPart = true;

            // Check that barcode scanned in not a string
            if (typeof barcode === 'string') {
                this.displayToast({text: 'This is not a valid part barcode', type: 'danger'});
                canAddPart = false;
            }
            if (!canAddPart) return;

            // Check if Apple device and if we're in the UK
            if(this.device.make_code === 'apple' && this.appRegion === 'UK') {
                //Check whether the device requires genuine parts
                if (this.device.genuine === 1 && barcode.data.genuine === 0) {
                    this.displayToast({text: 'Cannot add a non-genuine part to a genuine repair', type: 'danger'});
                    canAddPart = false;
                } else if(this.device.genuine === 0 && barcode.data.genuine === 1) {
                    this.displayToast({text: 'Cannot add a genuine part to a non-genuine repair', type: 'danger'});
                    canAddPart = false;
                }
                if (!canAddPart) return;
            }

            // Check if the part has already been added
            this.deviceParts.forEach(device => {
                if (barcode.id.toString() === device.id) {
                    this.displayToast({text: 'Part has already been added', type: 'danger'});
                    canAddPart = false;
                }
            });
            if (!canAddPart) return;

            // Check if the part has been marked as faulty
            this.devicePartsFaulty.forEach(device => {
                if (barcode.id.toString() === device.id) {
                    this.displayToast({text: 'Part is marked as faulty', type: 'danger'});
                    canAddPart = false;
                }
            });
            if (!canAddPart) return;

            this.barcode = barcode;
            this.loadStockItem({
                stockItemId: barcode.stock_id
            }).then(() => {
                let scannedStockItemHasTypeThatRequiresSerials = this.deviceModelStockItemTypesRequiredSerials.find(item => item.id == this.stockItem.type_id)
                if(scannedStockItemHasTypeThatRequiresSerials) {
                    this.serialTypeId = scannedStockItemHasTypeThatRequiresSerials.serial_type_id;
                    this.openSerialNumberScanModal(scannedStockItemHasTypeThatRequiresSerials.serial_type_id)
                    return;
                }

                this.runAddPart();
            });
        },

        /** Close an open modal */
        closeOpenModal() {
            this.modalOpen = false;
        },

        /** Load parts */
        loadPartLists() {
            this.loadParts({
                jobId: this.jobId,
                deviceId: this.deviceId
            });

            this.loadFaultyParts({
                jobId: this.jobId,
                deviceId: this.deviceId
            });
        },

        /** Open no parts required modal */
        openNoPartsRequiredModal() {
            this.modalOpen = true;
            this.$bvModal.show('no-parts-required-modal');
        },

        /** Open part faulty modal */
        openPartFaultyModal() {
            this.modalOpen = true;
            this.$bvModal.show('part-faulty-modal');
        },

        /** Open part not faulty modal */
        openPartNotFaultyModal() {
            this.modalOpen = true;
            this.$bvModal.show('part-not-faulty-modal');
        },

        /** Open remove part modal */
        openRemovePartModal() {
            this.modalOpen = true;
            this.$bvModal.show('remove-part-modal');
        },

        /** Open part serial number scan modal */
        openSerialNumberScanModal() {
            this.modalOpen = true;
            this.$bvModal.show('part-serial-no-scan-modal');
        },

        /** Display toast on part marked as faulty */
        partMarkedFaultyToast() {
            this.loadPartLists();
            return this.displayToast({text: 'Part marked as faulty', type: 'success'});
        },

        /** Run add pat on a part that was marked faulty before */
        partMarkedNotFaulty(data) {
            this.barcode = data.partScanned;
            this.runAddPart();
        },

        /** Display toast on part removal */
        partRemovedToast() {
            this.loadPartLists();
            return this.displayToast({text: 'Part removed', type: 'success'});
        },

        /** Add part function */
        runAddPart(oldSerialNumber = null, newSerialNumber = null, octaFabQrFrameOldSerialNumber = null) {
            if (this.addingPart) {
                return;
            }

            this.addingPart = true;

            this.$refs.addingPartModal.openModal();

            this.addPart({
                deviceId: this.deviceId,
                jobId: this.jobId,
                stockSerialNumberId:this.barcode.id,
                oldSerialNumber: oldSerialNumber,
                newSerialNumber: newSerialNumber,
                octaFabQrFrameOldSerialNumber: octaFabQrFrameOldSerialNumber
            }).then(() => {
                this.barcode = null;
                this.loadPartLists();
                this.addingPart = false;
                this.$refs.addingPartModal.partAdded();
            }).catch((errors) => {
                this.addingPart = false;
                this.$refs.addingPartModal.displayError(errors);
                this.$refs.serialNoScanModal.clearSerials();
            });
        },

        /** Run add part function once serial numbers have been scanned */
        serialsScanned(data) {
            this.runAddPart(data.oldSerial, data.newSerial, data.octaFabQrFrameOldSerial)
        }
    }
}
</script>

<style scoped>

</style>